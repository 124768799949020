<template>
	<div class="create-repairs">
		<van-form @submit="onSubmit" :show-error="false">
			<van-field readonly clickable required name="className" class="cell cell-right" :value="className" label="故障教室"
				input-align="right" placeholder="选择故障设备所在教室" @click="showPicker = true" is-link
				:rules="[{ required: true, message: '请填写故障教室' }]" />
			<van-popup v-model="showPicker" position="bottom" round>
				<van-picker show-toolbar value-key="label" :columns="columns" @confirm="classNameConfirm"
					@cancel="showPicker = false" title="操作选择" item-height='56px' visible-item-count='3' />
			</van-popup>
			<van-cell title="故障设备" center class="cell" required>
				<template #label>
					<div class="cell-device">
						<van-field name="device" class="checkbox-group-field" :rules="[{ required: true, message: '请填写故障设备' }]">
							<template #input>
								<van-checkbox-group v-model="deviceList">
									<div class="stepper">
										<van-checkbox name="黑板灯" class="check-box">黑板灯</van-checkbox>
										<van-stepper v-model="device[0].value" v-if="deviceList.includes('黑板灯')" button-size='32' />
									</div>
									<div class="stepper">
										<van-checkbox name="教室灯" class="check-box">教室灯</van-checkbox>
										<van-stepper v-model="device[1].value" v-if="deviceList.includes('教室灯')" button-size='32' />
									</div>
									<div class="stepper">
										<van-checkbox name="开关" class="check-box">开关</van-checkbox>
										<van-stepper v-model="device[2].value" v-if="deviceList.includes('开关')" button-size='32' />
									</div>
									<div class="stepper">
										<van-checkbox name="窗帘" class="check-box">窗帘</van-checkbox>
										<van-stepper v-model="device[3].value" v-if="deviceList.includes('窗帘')" button-size='32' />
									</div>
									<div class="stepper">
										<van-checkbox name="其他" class="check-box">其他</van-checkbox>
										<van-stepper v-model="device[4].value" v-if="deviceList.includes('其他')" button-size='32' />
									</div>
								</van-checkbox-group>
							</template>
						</van-field>
					</div>
				</template>
			</van-cell>
			<van-cell title="故障描述" center class="cell" required>
				<template #label>
					<van-field class="textarea" name="faultDescription" v-model="repairMessage.faultDescription" rows="3" autosize
						type="textarea" placeholder="输入内容" :rules="[{ required: true, message: '请填写故障描述' }]" />
				</template>
			</van-cell>
			<van-cell-group class="phone">
				<van-field v-model="repairMessage.phone" label="联系电话" class="cell cell-right" type="tel" name="phone"
					input-align="right" placeholder="留下您的电话，方便更快处理问题"  />
			</van-cell-group>
			<div class="submit-button">
				<van-button block type="info" native-type="submit">提交</van-button>
			</div>
		</van-form>
	</div>
</template>

<script>
import { repair } from '../../http/repair/repair';
import { classroom } from '../../http/repair/school';
export default {
	data() {
		return {
			checked: true,
			showPicker: false,
			device: [{
				name: '黑板灯',
				value: ''
			},
			{
				name: '教室灯',
				value: ''
			},
			{
				name: '开关',
				value: ''
			},
			{
				name: '窗帘',
				value: ''
			},
			{
				name: '其他',
				value: ''
			},],
			repairMessage: {
				schoolId: Number,
				classroomId: '',
				classroomIds: [],
				devices: [],
				faultDescription: '',
				phone: ''
			},
			stepper: 1,
			className: '',
			deviceList: [],
			columns: [],
		};
	},
	methods: {
		classNameConfirm(value, index) {
			this.showPicker = false
			this.className = value[0] + '-' + value[1] + '-' + value[2]
			let selectClassroomIds = []
			selectClassroomIds[0] = this.columns[index[0]].value
			selectClassroomIds[1] = this.columns[index[0]].children[index[1]].value
			selectClassroomIds[2] = this.columns[index[0]].children[index[1]].children[index[2]].value
			this.repairMessage.classroomIds = selectClassroomIds
			this.repairMessage.classroomId = selectClassroomIds[2]
		},
		async init() {
			const res = await classroom({
				schoolId: this.$store.state.schoolId
			})
			this.columns = res.data
			this.repairMessage.schoolId = Number(this.$store.state.schoolId)
		},
		async onSubmit(values) {
			this.repairMessage.devices = []
			for (let item of values.device) {
				for (let item2 of this.device) {
					if (item == item2.name) {
						this.repairMessage.devices.push(item2)
						break
					}
				}
			}
			const res = await repair(this.repairMessage)
			this.$router.push('/index')
		},
	},
	created() {
		this.init()
	}
}
</script>

<style lang="less" scoped>
.create-repairs {
	font-family: PingFangSC-Regular, PingFang SC;
}

.check-box {
	margin-top: 16px;
	font-size: 17px;
	color: #000000;
	line-height: 24px;
}

.cell-device {
	display: flex;
	justify-content: space-between;
}

.checkbox-group-field {
	padding: 0;
	width: 100%;

	/deep/ .van-field__error-message {
		margin-top: 16px;

	}
}

.van-checkbox-group {
	width: 100%;
}

.stepper {
	display: flex;
	justify-content: space-between;
}

.van-stepper {
	margin-top: 8px;
}

.textarea {
	padding: 0;

	/deep/ .van-field__control {
		text-align: left;
	}

}

.phone {
	margin-top: 16px;
}

.submit-button {
	margin: 16px;

	/deep/.van-button {

		border-radius: 6px;
		height: 50px;
		background: #378BF7;
	}
}
</style>