import http from '../request'
export async function repair(data) {
  return http.post('/mobile/repair', data)
}
export async function getRepairList(data) {
  return http.get('/mobile/repair', {
    params: data
  })
}
export async function getRepairDetail(id) {
  return http.get(`/mobile/repair/${id}`)
}
export async function finishRepair(id, data) {
  return http.put(`/mobile/repair/${id}`,data)
}